import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  ApiRef,
  BackstageIdentityApi,
  configApiRef,
  createApiFactory,
  createApiRef,
  discoveryApiRef,
  ProfileInfoApi,
  SessionApi,
} from '@backstage/core-plugin-api';
import {
  SamlAuth
} from '@backstage/core-app-api';

export const samlAuthApiRef: ApiRef<
    ProfileInfoApi &
    BackstageIdentityApi &
    SessionApi
> = createApiRef({
    id: 'internal.auth.saml',
});

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  createApiFactory(
    {
      api: samlAuthApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        configApi: configApiRef,
      },
      factory: ({ discoveryApi, configApi }) =>
        SamlAuth.create({
          discoveryApi,
          environment: configApi.getString('auth.environment')
        })
    }),

  ScmAuth.createDefaultApiFactory(),
];
